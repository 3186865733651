import { Link } from "react-router-dom";
import moment from 'moment';

const BulletTerms = ({isDiscount}) => {
  const endDate = moment().add(90, 'days').format('MMM D, YYYY');
  
  return (
    <div className="mt-10 shadow-lg bg-gray-200 p-5 rounded-lg">
        <ul className="list-disc pl-5 space-y-1 marker:text-gray-700 marker:text-lg">
          <li className="text-sm text-gray-600">You represent that you are over 18 years of age and agree if for whatever reason you're unhappy with your plan to contact customer support for a refund.</li>
          <li className="text-sm text-gray-600">You will only be charged {isDiscount ? '$67 today' : '3 payments of $29/mo starting today'} for your first quarter (details below)</li>
          <li className="text-sm text-gray-600">Your introductory period will last until {endDate}.</li>
          <li className="text-sm text-gray-600">You may cancel at any time before {endDate}, and you will not be charged.</li>
          <li className="text-sm text-gray-600">
            If you don't cancel, KetoSlim will automatically continue your membership at the end of your introductory period and charge the membership fee of 
            &nbsp;{isDiscount ? '$67 quarterly' : '$29/mo'} until you cancel.
          </li>
          <li className="text-sm text-gray-600">Your subscription will be bound by our
            &nbsp;<Link className="text-sm text-gray-600 underline" rel="noreferrer" target="_blank" to={'/terms&condition'} >Terms</Link> and 
            &nbsp;<Link className="text-sm text-gray-600 underline" rel="noreferrer" target="_blank" to={'/privacy-policy'} >Privacy Policy</Link>.
          </li>
          <li className="text-sm text-gray-600">If you would like a refund for any reason call 1-800-695-5045 or email <a className="text-sm text-gray-600" href="mailto:support@myketoslim.com">support@myketoslim.com</a>.</li>
        </ul>
      </div>
  );
}
 
export default BulletTerms;